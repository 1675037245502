// @flow

import React, {Component} from 'react';
import type {ChartMessage, Project, Topic} from "../../../../model/model";
import styles from './index.css';
import {LLMsg} from "../../../../IntlCapture";
import Api from "../../../../bundle/Api";
import _ from "lodash";
import * as PubSub from "pubsub-js";
import {ClassNames} from "../../../../bundle/ClassNames";
import ReactTooltip from 'react-tooltip';


type Props = {
    project: Project
}

type State = {}

const am4core = window.am4core;
const am4charts = window.am4charts;

class ProjectOverviewTopicChartFragment extends Component<Props, State> {

    chart;
    chartRef;

    constructor(props: Props) {
        super(props);
        this.chartRef = React.createRef();
    }

    subToken: any;

    componentWillUnmount() {
        this.chart.dispose();
        PubSub.unsubscribe(this.subToken);
    }

    componentDidMount() {
        this.chart = am4core.create(this.chartRef.current, am4charts.XYChart);
        this.chart.responsive.enabled = true;
        // Add data
        let topics = _.sortBy(this.props.project.topics, t => {
            return t.topicId
        });
        this.chart.data = topics.map((topic: Topic) => {
            return {
                "topicName": topic.description + "\n(" + topic.topicName + ")",
                "count": topic.totalNumberOfDataRecords
            }
        })

        var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "topicName";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.minHeight = 110;

        var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 50;

        var series = this.chart.series.push(new am4charts.ColumnSeries());
        series.sequencedInterpolation = true;
        series.dataFields.valueY = "count";
        series.dataFields.categoryX = "topicName";
        series.columns.template.strokeWidth = 0;

        series.name = "Visits";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;

        series.tooltip.pointerOrientation = "vertical";

        series.columns.template.column.cornerRadiusTopLeft = 10;
        series.columns.template.column.cornerRadiusTopRight = 10;
        series.columns.template.column.fillOpacity = 0.8;

        var hoverState = series.columns.template.column.states.create("hover");
        hoverState.properties.cornerRadiusTopLeft = 0;
        hoverState.properties.cornerRadiusTopRight = 0;
        hoverState.properties.fillOpacity = 1;

        series.columns.template.adapter.add("fill", (fill, target) => {
            return this.chart.colors.getIndex(target.dataItem.index);
        });

        this.subToken = PubSub.subscribe(Api.actions.topic.TOPIC_NEW_DATA_ACTION_TYPE, (actionName: string, data: ChartMessage) => {
            this.chart.data.forEach((item) => {
                if (item.topicName === data.topicName) {
                    item.count = item.count + 1;
                    this.chart.invalidateData();
                }
            })
        });
    }

    render() {
        return <div className={ClassNames("m-portlet mb-0")}>
            <div className="m-portlet__body">

                <div className={styles.header}>
                    {LLMsg("COMMON.PROJECT.TOTAL_MESSAGE_COUNT")}

                    <div data-tip={LLMsg("COMMON.PROJECT.EXPLAIN_TOPIC_MESSAGE_COUNT")} className={styles.explainButton}><i className={"flaticon-info m--icon-font-size-lg3"}/></div>
                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                </div>

                <div ref={this.chartRef} style={{width: "100%", height: "440px"}}/>
            </div>
        </div>;
    }
}

export default ProjectOverviewTopicChartFragment;