// @flow

import React, {Component} from 'react';
import type {Project, Topic} from "../../../../model/model";
import styles from './index.css';
import {LLMsg} from "../../../../IntlCapture";
import {ClassNames} from "../../../../bundle/ClassNames";
import ReactTooltip from 'react-tooltip';

type Props = {
    project: Project
}
type State = {};


const am4core = window.am4core;
const am4charts = window.am4charts;

export default class ProjectOverviewRadarChartFragment extends Component<Props, State> {
    chart: any;
    chartRef: any;

    constructor(props: Props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentWillUnmount() {
        this.chart.dispose();
    }

    getScoreForIot(): number {
        let totalData = 0;
        this.props.project.topics.forEach((topic: Topic) => {
            totalData += topic.totalNumberOfDataRecords;
        })
        if (totalData >= 500) return 100;
        let result = totalData / 500 * 100;
        if (result <= 5) return 5;
        return 5;
    }

    getScoreForChart(): number {
        let count = this.props.project.chartCount;
        if (count >= 30) return 100;

        let result = count / 30 * 100;
        if (result <= 5) return 5;
        return 5;
    }

    getScoreForEventTrigger(): number {
        let count = this.props.project.eventTriggerCount;
        if (count >= 10) return 100;

        if (count === 0) return 5;
        return count / 10 * 100;
    }

    getScoreForCollaboration(): number {
        let count = this.props.project.projectUsers.length;
        if (count >= 5) return 100;
        return count / 5 * 100;
    }

    componentDidMount() {
        this.chart = am4core.create(this.chartRef.current, am4charts.RadarChart);

        /* Add data */
        this.chart.data = [{
            "country": LLMsg("COMMON.PROJECT.AI_USAGE"),
            "litres": 5
        }, {
            "country": LLMsg("COMMON.PROJECT.IOT_USAGE"),
            "litres": this.getScoreForIot()
        }, {
            "country": LLMsg("COMMON.PROJECT.VISUALIZATION_USAGE"),
            "litres": this.getScoreForChart()
        }, {
            "country": LLMsg("COMMON.PROJECT.EVENT_TRIGGER_USAGE"),
            "litres": this.getScoreForEventTrigger()
        }, {
            "country": LLMsg("COMMON.PROJECT.COLLABORATION_USAGE"),
            "litres": this.getScoreForCollaboration()
        }];

        /* Create axes */
        var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "country";

        var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.axisFills.template.fill = this.chart.colors.getIndex(2);
        valueAxis.renderer.axisFills.template.fillOpacity = 0.05;
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;

        /* Create and configure series */
        var series = this.chart.series.push(new am4charts.RadarSeries());
        series.dataFields.valueY = "litres";
        series.dataFields.categoryX = "country";
        series.name = "Sales";
        series.strokeWidth = 3;
        series.fillOpacity = 0.5;
        series.fill = am4core.color("green");

    }

    render(): React$Node {

        return <div className={ClassNames("m-portlet mb-0")}>
            <div className="m-portlet__body">
                <div className={styles.title}>
                    {LLMsg("COMMON.PROJECT.PROJECT_USAGE_ANALYSIS")}

                    <div data-tip={LLMsg("COMMON.PROJECT.PROJECT_USAGE_ANALYSIS")} className={styles.explainButton}><i className={"flaticon-info m--icon-font-size-lg3"}/></div>
                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                </div>
                <div ref={this.chartRef} className={styles.chart}/>
            </div>
        </div>
    }
}